import React from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { FeedbackTooltip } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { BUTTON_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';
import { InputVRM } from '@motorway/mw-highway-code/pre-alpha';

import cypressIds from 'CypressId';

import { vehicleType } from 'Utilities/helpers';
import { applyCypressData } from 'Utilities/index';

import { useBreakpoint } from '../../../../../../../components/misc/Breakpoints/Breakpoints';
import useHeroVrmInput from '../../../../../fastlane/hooks/useHeroVrmInput';
import useShowNewHomepage from '../../../../../homepage/Hooks/useShowNewHomepage';
import { useShowLandingPageExperiment } from '../../../../../landingPages/Hooks/useShowLandingPageExperiment';

import LocalTexts from './HeroInputVrmTexts.json';

import styles from './HeroInputVrm.scss';

const LocalT = new MDText(LocalTexts);

type HeroInputVrmProps = {
  loadingHandler: (value: string) => void;
  onError?: () => void;
  onSuccess?: (value: string) => void;
  setShowMileage: (value: boolean) => void;
  vrm: string;
};

const t = (key: string, args?: Record<string, string | React.ReactNode>) => `${LocalT.translate(key, args)}`;

const TEXTS = {
  ctaAgain: t('cta.again'),
  label: t('vrmChecker.label'),
  placeholder: t('vrmChecker.placeholder'),
};

export const HeroInputVrm = ({
  loadingHandler,
  onError,
  onSuccess,
  setShowMileage,
  vrm = '',
}: HeroInputVrmProps) => {
  const location = useLocation();

  const isHomepage = location.pathname === '/';

  const { showLandingPagesHeroRedesign } = useShowLandingPageExperiment();

  const { showNewHomepage } = useShowNewHomepage();

  const {
    getErrorMsg,
    hasApiError: isApiError,
    loading,
    onFormSubmit,
    onInputBlur,
    onInputChange,
    onInputFocus,
    showIntent,
    showIntentInput,
    vrmValue,
    wrongVRM,
  } = useHeroVrmInput({
    isHomepage,
    loadingHandler,
    onError,
    onSuccess,
    setShowMileage,
    vrm,
  });

  const breakpoints = useBreakpoint();
  const isMobile = !!breakpoints.maxWidth.breakpointTabletMobile;
  const placement = !isMobile && isHomepage ? 'bottom' : 'top';

  const ctaLabel = (isApiError || wrongVRM)
    ? TEXTS.ctaAgain
    : t('cta.valueCar', { vehicle: vehicleType(location.pathname) });

  const commonInputAttributes = {
    ...applyCypressData(cypressIds.fields.vrmInputField),
    onBlur: onInputBlur,
    onChange: ({ currentTarget: { value } }: { currentTarget: { value: string }}) => onInputChange(value),
    onFocus: () => onInputFocus(),
    placeholder: TEXTS.placeholder,
  };

  const isLandingPageExperiment = !isHomepage && showLandingPagesHeroRedesign;

  const inputAriaLabels = {
    'aria-errormessage': 'errorTooltip',
    'aria-invalid': showIntent() === 'error',
    'aria-required': 'true',
  };
  const tooltipContent = <span id='errorTooltip'>{ LocalT.translate(`vrmChecker.${getErrorMsg()}`) }</span>;

  return (
    <Form
      onSubmit={() => onFormSubmit()}
      render={({ handleSubmit }) => (
        <form
          className={cx(styles.component, {
            [styles.heroVrmInputLandingPage]: isLandingPageExperiment,
            [styles.nextHomepage]: isHomepage && showNewHomepage,
          })}
          data-cy="vrmInputForm"
          onSubmit={handleSubmit}
        >
          <Field
            id='vrm-input'
            name='vrm-input'
          >
            {({ id, input }) => (
              <fieldset className={styles.fieldset}>
                <label className={styles.label} htmlFor={id}>{TEXTS.label}</label>
                <FeedbackTooltip
                  appendTo={'parent'}
                  className={cx(styles.tooltip, { [styles.hidden]: wrongVRM })}
                  content={tooltipContent}
                  intent={showIntentInput()}
                  interactive={false}
                  {...{ placement }}
                >
                  <section className={styles.inputWrapper}>
                    <InputVRM
                      data-thc-vrm-input
                      fullWidth
                      formProps={{
                        input: {
                          ...input,
                          ...commonInputAttributes,
                          ...inputAriaLabels,
                          value: vrmValue,
                        },
                      }}
                      id={id}
                      intent={showIntent() ?? undefined}
                      label={''}
                      showLabel={false}
                      size={ SIZE.LARGE }
                    />
                  </section>
                </FeedbackTooltip>
              </fieldset>
            )}
          </Field>
          <Button
            data-thc-button
            fullWidth
            icon="chevron"
            label= {ctaLabel}
            loading={loading}
            size={ SIZE.LARGE }
            type={BUTTON_TYPE.SUBMIT}
            variant={VARIANT.PRIMARY}
            {...applyCypressData(cypressIds.buttons.valueCarButton)}
          />
        </form>
      )}
    />
  );
};
